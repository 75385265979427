import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { BedDouble, ChevronLeft, ChevronRight, Ruler } from 'lucide-react';
import Footer from './Footer';
import { OverviewFilters, OverviewPaging, OverviewSorting, Property } from '../types/overviewTypes';
import geo_data from './geo.json';
import { FilteredGeoData } from '../types/geoDataTypes';
import Select from "react-select";

const xClientId = process.env.REACT_APP_X_CLIENT_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const serverId = process.env.REACT_APP_SERVER_ID;
const apiKey = process.env.REACT_APP_API_KEY;

const SkeletonLoader: React.FC = () => {
  return (<div className="animate-pulse">
    <div className="bg-gray-300 h-64 w-full rounded-lg mb-4"></div>
    <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
    <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
    <div className="h-10 bg-gray-300 rounded w-full"></div>
  </div>)
};
const headers = {
  'X-CLIENT-ID': xClientId,
  'client_id': clientId,
  'server_id': serverId,
  'api_key': apiKey,
  'extended': true
};
const fetchProperties = async (filters: OverviewFilters | null, paging: OverviewPaging | null, initialSorting: OverviewSorting | null) => {
  const url = 'https://gateway-cmsapi.v2.zabun.be/api/v1/property/search?extended=true';
  try {
    const response = await axios.post(url, { filtering: filters, sorting: initialSorting, paging: paging }, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw new Error("Failed to fetch properties");
  }
};

export function cleanCityName(city: string) {
  return city.replace(/\s?\(\d+\)/, '').trim();
}

export const daysSinceCreation = (creationDate: string) => {
  const currentDate = new Date();
  const creation = new Date(creationDate);

  const timeDifference = currentDate.getTime() - creation.getTime();

  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return daysDifference;
};


const InputField: React.FC<{ label: string; value: string; placeholder: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({
  label,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="flex flex-col mb-2">
      <p className="text-sm font-medium text-gray-700 mb-1">{label}</p>
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full m-0 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out text-base"
      />
    </div>
  );
};

const PropertyList: React.FC = () => {
  const [pageSize] = useState(20);
  const [properties, setProperties] = useState<Property[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [zipCodeData, setZipCodeData] = useState<FilteredGeoData>({});
  const [filters, setFilters] = useState<OverviewFilters | null>({ city_geo_ids: [], transaction_ids: [1, 2, 3, 5, 6] ,status_ids:[1] });
  const [paging, setPaging] = useState<OverviewPaging | null>({ page: 0, size: pageSize });
  const [initialSorting, setInitialSorting] = useState<OverviewSorting | null>({ sort: "CREATED", order: "DESC" });
  const [totalItems, setTotalItems] = useState(0);

  const [selectedGeoId, setSelectedGeoId] = useState<number | null>(null);
  const [selectedMin, setSelectedMin] = useState<number | undefined>(undefined);
  const [selectedMax, setSelectedMax] = useState<number | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<number>(1);
  const [selectedSortingType, setSelectedSortingType] = useState<number>(1);
  const [showSold, setShowSold] = useState<boolean | undefined>(true);

  const filtered_geo_data = useMemo(() => {
    const goe_ids = properties?.map((p) => p.address.city_geo_id);
    return geo_data
      .filter((g) => g.region1 === "Région Flamande")
      .filter((g) => goe_ids?.includes(g.city_geo_id))
      .reduce<FilteredGeoData>((acc, item) => {
        acc[item.zip] = {
          city: item.city,
          city_geo_id: item.city_geo_id
        };
        return acc;
      }, {} as FilteredGeoData);
  }, [properties, geo_data]);

  useEffect(() => {
    if (!properties) return;
    if (properties.length > 0 && Object.keys(zipCodeData).length === 0) {
      setZipCodeData(filtered_geo_data)
    }
  }, [properties, filtered_geo_data]);

  const debouncedFetchProperties = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const data = await fetchProperties(filters, paging, initialSorting);

        setTotalItems(data.total);
        setProperties(data.properties);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    }, 300),
    [filters, paging]
  );

  useEffect(() => {
    debouncedFetchProperties();
  }, [debouncedFetchProperties]);

  const OnSubmit = () => {

    setSelectedSortingType(1);

    const transaction_ids = [selectedType];

    if (!!showSold) {
      transaction_ids.push(selectedType === 1 ? 5 : 6);
      if (selectedType === 1) {
        transaction_ids.push(3)
      }
    }

    setFilters((prevFilters) => ({
      city_geo_ids: !!selectedGeoId ? [selectedGeoId] : [],
      price: {
        minimum: !!selectedMin ? selectedMin : 0,
        maximum: !!selectedMax ? selectedMax : 100000000,
      },
      transaction_ids: transaction_ids,
      status_ids: prevFilters?.status_ids  ||  [1]
    }));
  };


  const handleZipSelect = (selectedZip: string) => {
    if (!selectedZip) {
      setSelectedGeoId(null);
      return;
    }
    const geo_id = zipCodeData[selectedZip].city_geo_id
    setSelectedGeoId(geo_id);
  }

  const zipCodeOptions = Object.keys(zipCodeData).map((zipCode) => {
    const cityData = zipCodeData[zipCode];
    return {
      value: zipCode,
      label: cityData.city,
      cityGeoId: cityData.city_geo_id,
    };
  });

  const transactionIdMappings: Record<string, { label: string; transactionId: number }> = {
    TE_KOOP: { label: "te koop", transactionId: 1 },
    TE_HUUR: { label: "te huur", transactionId: 2 },
  };

  const transActionTypeOptions = ["TE_KOOP", "TE_HUUR"].map((s) => {
    return {
      value: s,
      label: transactionIdMappings[s].label,
      transactionId: transactionIdMappings[s].transactionId,
    };
  });

  const sortingIdMappings: Record<string, { label: string; sortingId: number }> = {
    MEEST_RECENT: { label: "meest recent", sortingId: 1 },
    PRIJS_OPLOPEND: { label: "prijs oplopend", sortingId: 2 },
    PRIJS_AFLOPEND: { label: "prijs aflopend", sortingId: 3 },
  };

  const sortingOptions = ["MEEST_RECENT", "PRIJS_OPLOPEND", "PRIJS_AFLOPEND"].map((s) => {
    return {
      value: s,
      label: sortingIdMappings[s].label,
      sortingId: sortingIdMappings[s].sortingId,
    };
  });

  const selectedOption = zipCodeOptions.find(
    (option) => option.cityGeoId === selectedGeoId
  ) || null;

  const handleZipSelectorChange = (selected: any) => {
    handleZipSelect(selected ? selected.value : "");
  };

  const handleTypeSelectorChange = (
    newValue: { value: string; label: string; transactionId: number } | null
  ) => {
    const transactionId = newValue ? newValue.transactionId : undefined;
    setSelectedType(transactionId || 1);
  };

  const handleSortingSelectorChange = (
    newValue: { value: string; label: string; sortingId: number } | null
  ) => {
    const sortingId = newValue ? newValue.sortingId : undefined;
    setSelectedSortingType(sortingId || 1);
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || value === "") {
      setSelectedMin(undefined);
      return;
    };
    if (value === "") {
      setSelectedMin(undefined);
    } else {
      setSelectedMin(parsedValue);
    }
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || value === "") {
      setSelectedMax(undefined);
      return;
    };
    if (value === "") {
      setSelectedMax(undefined);
    } else {
      setSelectedMax(parsedValue);
    }
  };

  const totalPages = useMemo(() => Math.ceil(totalItems / pageSize), [totalItems]);

  const handleNextPage = () => {
    window.scrollTo(0, 0);
    setPaging(prevPaging => ({
      page: (prevPaging?.page || 0) + 1,
      size: pageSize
    }));

  };

  const handlePrevPage = () => {
    window.scrollTo(0, 0);
    setPaging(prevPaging => ({
      page: (prevPaging?.page || 0) - 1,
      size: pageSize
    }));
  };

  useEffect(() => {
    if (!properties || Object.keys(zipCodeData).length === 0) return;
    const sorted = [...properties];
    switch (selectedSortingType) {
      case 2:
        sorted.sort((a, b) => a.price - b.price);
        break;
      case 3:
        sorted.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setProperties(sorted);
  }, [selectedSortingType]);

  if (loading)
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mx-4 lg:mx-24 mb-10">
        {[...Array(6)].map((_, idx) => (
          <SkeletonLoader key={idx} />
        ))}
      </div>
    );

  return (
    <>
      <div className="">

        <div className='mx-0 lg:mx-64 md:mx-28  bg-orange-100'>
          <div className='p-4 mb-6 pb-8'>

            <div className='grid lg:grid-cols-[1fr_1fr_1fr] grid-cols-1 gap-x-10'>

              <div className='mb-2 lg:mb-0'>
                <p className="text-sm font-medium text-gray-700 mb-1">Regio</p>
                <Select
                  id="city-select"
                  className="text-base relative"
                  value={selectedOption}
                  onChange={handleZipSelectorChange}
                  options={zipCodeOptions}
                  placeholder="Zoek een Regio"
                  isClearable
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                  }}
                />
              </div>

              <InputField
                value={selectedMin?.toString() || ""}
                label="Minimum prijs"
                placeholder="€0"
                onChange={(e) => handleMinPriceChange(e, e.target.value)}
              />
              <InputField
                value={selectedMax?.toString() || ""}
                label="Maximum prijs"
                placeholder="€0"
                onChange={(e) => handleMaxPriceChange(e, e.target.value)}
              />
            </div>

            <div className='grid lg:grid-cols-[1fr_1fr_1fr] grid-cols-1 gap-x-10'>
              <div className='mb-2 lg:mb-0'>
                <p className="text-sm font-medium text-gray-700 mb-1">Type</p>
                <Select
                  className="text-base relative"
                  value={transActionTypeOptions.find(option => option.transactionId === selectedType)}
                  onChange={handleTypeSelectorChange}
                  options={transActionTypeOptions}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                  }}
                />
              </div>
              <div className="flex items-center mt-6">
                <input
                  type="checkbox"
                  id="show-sold"
                  checked={showSold}
                  onChange={(e) => setShowSold(e.target.checked)}
                  className="w-5 h-5 text-blue-600 border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                />
                <label htmlFor="show-sold" className="ml-2 text-sm font-medium text-gray-700">
                  Toon verkocht/verhuurd
                </label>
              </div>
              <button onClick={OnSubmit} className='bg-orange-400 text-white px-6 py-2 rounded-lg hover:bg-orange-500 h-fit mt-4 self-center'>
                Zoek
              </button>


            </div>
          </div>
        </div>

        {
          !!properties && properties.length === 0 &&
          <div className="h-screen md:mx-24 text-gray-500">
            <p>Geen resultaten</p>
          </div>
        }

        {
          !!properties && properties.length >= 1 &&

          <div className='grid grid-cols-1 md:grid-cols-[auto_1fr_auto] gap-x-2 mx-4'>
            <div className="mx-4 md:mx-24 text-gray-500 order-2 md:order-1">
              <p>{totalItems} resultaten</p>
            </div>
            <div></div>
            <div className='mb-2 lg:mb-0 mx-4 md:mx-24 order-1 md:order-2 mb-8 lg:mb-0'>
              <p className="text-sm font-medium text-gray-700 mb-1">Sorteren op</p>
              <Select
                className="text-base relative"
                value={sortingOptions.find(option => option.sortingId === selectedSortingType)}
                onChange={handleSortingSelectorChange}
                options={sortingOptions}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
              />
            </div>
          </div>

        }

        {
          !!error &&
          <div className="h-screen mx-24 text-gray-500">
            <p>Er is een probleem opgetreden.</p>
          </div>
        }

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10 mx-8 md:mx-24 mb-10">
          {properties
            ?.filter((property) => !!property.photo_url)
            .sort((a, b) => {
              if (selectedSortingType === 1) {
                return new Date(b.creation).getTime() - new Date(a.creation).getTime();
              }
              return 0;
            })
            .map((property) => (
              <div
                key={property.property_id}
                className={`relative bg-white shadow-lg rounded-lg overflow-hidden hover:cursor-pointer group`}
                onClick={() => {
                  window.location.href = `/aanbod/${property.property_id}`;
                }}
              >
                {property.transaction_id === 5 && (
                  <div className="absolute top-6 -left-14 rotate-[-36deg] bg-orange-400 text-white px-20 py-2 text-xl font-bold z-30">
                    VERKOCHT
                  </div>
                )}

                {property.transaction_id === 6 && (
                  <div className="absolute top-6 -left-14 rotate-[-36deg] bg-orange-300 text-white px-20 py-2 text-xl font-bold z-30">
                    VERHUURD
                  </div>
                )}

                {property.transaction_id === 3 && (
                  <div className="absolute top-6 -left-14 rotate-[-36deg] bg-orange-700 text-white px-20 py-2 text-xl font-bold z-30">
                    IN OPTIE
                  </div>
                )}

                {daysSinceCreation(property.creation) < 30 && (
                  <div className="absolute top-4 right-4 z-10 bg-orange-400 text-white px-4 py-1 text-sm font-semibold rounded-full">
                    NIEUW
                  </div>
                )}

                <div className="w-full h-64 overflow-hidden">
                  <img
                    src={property.photo_url}
                    alt={`Property ${property.reference}`}
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                    loading="lazy"
                  />
                </div>
                <div className="p-0">
                  <div className="grid grid-cols-[1.5fr_2fr]">
                    <div className="p-1">

                      {property.transaction_id === 5 && (
                        <p className="text-md font-bold text-sky-900 py-1 !mt-0">
                          Verkocht
                        </p>
                      )}

                      {property.transaction_id === 6 && (
                        <p className="text-md font-bold text-sky-900 py-1 !mt-0">
                          Verhuurd
                        </p>
                      )}

                      {property.transaction_id !== 6 && property.transaction_id !== 5 &&(
                        <p className="text-md font-bold text-sky-900 py-1 !mt-0">
                          € {property.price.toLocaleString()},-
                        </p>
                      )}



                    </div>
                    <div className="grid grid-cols-[auto_auto_1fr_1fr] bg-orange-400 gap-x-1 px-1">
                      <div className="flex items-center text-white font-bold text-md space-x-2 pl-2">
                        <BedDouble className="w-4 h-4 " />
                        <span className="text-lg font-semibold leading-tight">{property.bedroom_count}</span>
                      </div>
                      <div className="flex items-center text-white font-bold text-md space-x-1">
                        <Ruler className="w-4 h-4" />
                        <span className="text-lg font-semibold leading-tight">{property.area_build} m²</span>
                      </div>
                    </div>
                  </div>

                  <div className="bg-orange-100 p-2 pt-2">
                    <p className="text-lg font-bold text-gray-800 !mt-0">
                      {property.address.city_geo.zip.toUpperCase()} {cleanCityName(property.address.city_geo.city).toUpperCase()}
                    </p>
                    <p className="text-lg text-gray-700 !mt-0">
                      {property.address.street} {property.address.number}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="flex justify-center items-center mt-4  mb-4">
        <div className="flex justify-between items-center w-fit">
          <button onClick={handlePrevPage} disabled={paging?.page === 0} className="text-orange-400 border custom-border rounded-md">
            <ChevronLeft className='h-8 w-8' />
          </button>
          <div className='px-2'>
            Pagina {!!paging?.page ? paging?.page + 1 : 1} van {totalPages}
          </div>
          <button onClick={handleNextPage} disabled={paging?.page === totalPages - 1} className="text-orange-400 border custom-border rounded-md">
            <ChevronRight className='h-8 w-8' />
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PropertyList;
